<template>
  <div>
    <v-row justify="end">
      <v-col style="flex: none; width: fit-content">
        <v-btn class="button button-outlined primary" @click="newItem">
          New Item
        </v-btn>
      </v-col>
    </v-row>
    <TableReadOnly
      :headerTable="headerTable"
      :items="items"
      :disableValues="disableValues"
    >
      <template v-slot:actions="{ item, index }">
        <v-row>
          <v-col>
            <v-btn
              color="transparent"
              class="lighten-2 rounded-0 pa-0"
              @click="editItem(index)"
              icon
            >
              <v-icon dark v-if="item.edit === true">mdi-content-save</v-icon>
              <v-icon dark v-else>mdi-account-edit</v-icon>
            </v-btn>
          </v-col>
          <v-col>
            <v-btn
              color="transparent"
              class="lighten-2 rounded-0 pa-0"
              @click="deleteItem(index)"
              icon
            >
              <v-icon dark>mdi-delete</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </template>

      <template v-slot:append="{ headers }">
        <tr>
          <td
            style="background-color: #3b3c55"
            v-for="(header, i) in headers"
            :key="i"
          >
            <div v-if="header.value == 'qty'">
              {{ totalQty }}
            </div>

            <div v-if="header.value == 'buyerPrice'">
              {{ parseFloat(totalAmount).toFixed(2) }}
            </div>
          </td>
        </tr>
      </template>
    </TableReadOnly>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { generateHeaderData } from "@/utils/tables.js";
import TableReadOnly from "@/components/General/TableReadOnly.vue";

export default {
  props: ["details"],
  components: {
    TableReadOnly,
  },
  created() {
    this.conditionalHeader();
    this.headerTable = generateHeaderData(this.header);
    this.detailsItems = this.details;
  },
  computed: {
    ...mapState({
      tabSelected: (state) => state.moduleOrders.tabSelected,
    }),
    tabDetails() {
      return this.tabSelected.details;
    },
    items() {
      this.disableValues = [];
      return this.detailsItems.map((detail) => {
        detail.buyerPrice = parseFloat(detail.buyerPrice).toFixed(2);
        if (!detail.new)
          if (detail.ContainerNo && detail.ContainerNo.length > 0)
            detail["disable"] = "qty";
          else detail["disable"] = "ContainerNo";

        return detail;
      });
    },
    totalQty() {
      let total = 0;
      this.items.map(({ qty }) => (total += +qty));
      return total;
    },
    totalAmount() {
      let total = 0;
      this.items.map(({ buyerPrice, qty }) => (total += +buyerPrice * +qty));
      return total;
    },
  },
  data() {
    return {
      detailsItems: [],
      header: [
        { text: "Item #", value: "docItemID" },
        { text: "Country", value: "countryName" },
        { text: "State", value: "stateName" },
        { text: "Container No.", value: "ContainerNo" },
        { text: "Manufacture Year", value: "ManufactureYear" },
        { text: "City", value: "cityName" },
        { text: "Depot", value: "Location" },
        { text: "Size", value: "sizeDescr" },
        { text: "Type", value: "typeCode" },
        { text: "Reported Condition", value: "condDescr" },
        { text: "Qty", value: "qty" },
      ],
      headerTable: [],
      disableValues: [],
    };
  },
  watch: {
    details() {
      this.detailsItems = this.details;
    },
  },
  methods: {
    conditionalHeader() {
      if (
        this.tabDetails["vendStepDescr"] == "Release Available" ||
        this.tabDetails["custStepDescr"] == "Pending Pick Up"
      ) {
        this.header.push({
          text: "Release #",
          value: "rn2_release_ref",
        });
        this.header.push({ text: "Payment #", value: "cust_pay_no" });
      }

      if (this.tabDetails["docType"] !== "RFQ")
        this.header.push({ text: "Unit Price", value: "buyerPrice" });

      this.header.push({ text: "", value: "actions", width: "5%" });
    },
    newItem() {
      let newItem = {
        docItemID: "",
        countryName: "",
        stateName: "",
        cityName: "",
        Location: "",
        sizeDescr: "",
        typeDescr: "",
        condDescr: "",
        rn2_release_ref: "",
        cust_pay_no: "",
        qty: "",
        buyerPrice: 0,
        edit: true,
        new: true,
        disable: "",
      };

      this.detailsItems.push(newItem);
    },
    editItem(item) {
      this.$set(this.details[item], "edit", true);
      // this.details[item].edit = true;
      console.log(this.details[item]);
    },
    deleteItem() {
      console.log("delete item");
    },
  },
};
</script>
