<template>
  <v-col class="pa-0 ma-0" cols="12">
    <v-row dense class="ma-0" style="gap: 8px">
      <v-col class="py-0" v-for="value in Titles" :key="value.index">
        <v-btn
          class="pa-2 ma-0 primary--text dark-blue"
          dense
          block
          @click="tabClicked(value)"
        >
          {{ value.title }}
          <v-badge bordered color="primary" inline :content="value.content"></v-badge>
        </v-btn>
        <!-- </v-badge> -->
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import { mapMutations, mapGetters, mapState } from "vuex";
export default {
  created() {
    this.setTabSelected(this.Titles[0]);
  },
  computed: {
    ...mapState({
      openLength: (state) => state.moduleOrders.openLength,
      completeLength: (state) => state.moduleOrders.completeLength,
      historyLength: (state) => state.moduleOrders.historyLength,
    }),
    Titles() {
      return [
        {
          index: 0,
          title: "Open",
          content: `${this.openLength}`,
          details: {},
        },
        {
          index: 1,
          title: "Complete",
          content: `${this.completeLength}`,

          details: {},
        },
        {
          index: 2,
          title: "History",
          content: `${this.historyLength}`,

          details: {},
        },
        // {
        //   index: 3,
        //   title: "Draft",
        // },
      ];
    },
  },
  data() {
    return {};
  },
  methods: {
    ...mapMutations({
      setTabSelected: "moduleOrders/setTabSelected",
    }),
    tabClicked(value) {
      if (this.$route.params.docNo) {
        let selected = { ...value };
        selected.details = {};
        this.setTabSelected(selected);
        this.$router.replace("/orders");
      } else this.setTabSelected(value);
    },
  },
};
</script>

<style></style>
