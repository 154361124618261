<template>
  <v-row
    class="ma-0 px-5 py-1 dark_blue lighten-1 rounded-lg"
    justify="space-between"
    align="center"
  >
    <v-col
      cols="1"
      class="text-subtitle-1 font-weight-bold d-flex"
      :style="{ backgroundColor: '#555' }"
    >
      <span class="w-100 text-center"> Details </span>
    </v-col>

    <v-col class="row justify-end" style="gap: 10px">
      <template>
        <v-tooltip
          v-for="{ name, color, tooltipText, method } in buttonsName"
          :key="name"
          bottom
          color="primary"
          :disabled="!!tooltipText.length"
        >
          <template v-slot:activator="{ on }">
            <v-btn
              class="button elevation-5"
              :color="color || 'primary'"
              v-on="on"
              @click="handlerEventButton(method)"
              small
            >
              {{ name }}
            </v-btn>
          </template>
          <span>{{ tooltipText }}</span>
        </v-tooltip>
      </template>
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
export default {
  computed: {
    ...mapState({
      tabSelected: (state) => state.moduleOrders.tabSelected,
    }),
    tabDetails() {
      return this.tabSelected.details;
    },
    buttonsName() {
      return [
        {
          name: "Save",
          tooltipText: "Save Quote",
          method: "saveQuote",
        },
      ];
    },
  },
  data() {
    return {};
  },
  methods: {
    ...mapActions({
      actionCancelOrder: "moduleOrders/cancelOrder",
    }),
    ...mapMutations({
      setDetailsStore: "moduleOrders/setDetails",
    }),

    handlerEventButton(method) {
      if (this[method]) this[method]();
    },
    saveQuote() {
      this.$emit("saveQuote");
    },
  },
};
</script>

<style lang="scss" scoped></style>
