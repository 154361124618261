<template>
  <v-list-item-subtitle class="ma-0 px-2 subtitle" tag="span">
    <v-select
      v-if="type === 'dropdown'"
      v-model="obj[value]"
      :items="drowpdownValues.values"
      :item-text="drowpdownValues.text ? drowpdownValues.text : ''"
      :item-value="drowpdownValues.value ? drowpdownValues.value : ''"
      single-line
      dense
      solo
      background-color="#20212E"
      hide-details
      class="lighten-2 pa-0 ma-0 elevation-5 text-center non-outlined.v-text-field--outlined"
    >
    </v-select>
    <DatePicker
      v-model="obj[value]"
      :data="obj"
      :keyData="value"
      :existingModel="obj[value]"
      v-else-if="type === 'datepicker'"
      :hideDetails="hideDetails"
    ></DatePicker>
    <v-text-field
      v-else-if="type === 'textfield'"
      v-model="obj[value]"
      type="text"
      outlined
      dense
      single-line
      hide-details
      background-color="#20212E"
    >
    </v-text-field>

    <template v-else>
      {{ obj[value] }}
    </template>
  </v-list-item-subtitle>
</template>

<script>
import DatePicker from "@/components/General/DatePickerGeneral.vue";

export default {
  props: ["field", "value", "type", "drowpdownValues", "obj", "hideDetails"],
  components: {
    DatePicker,
  },
};
</script>

<style lang="scss" scoped></style>
