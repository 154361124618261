<template>
  <TableReadOnly
    :headerTable="headerTable"
    :items="orderActivities"
    class="table--word-wraper"
    sortBy="id"
    :sortDesc="true"
  />
</template>

<script>
import TableReadOnly from "@/components/General/TableReadOnly.vue";
import { generateHeaderData } from "@/utils/tables.js";
import { mapActions, mapGetters, mapState } from "vuex";
export default {
  // props: ["details"],
  components: {
    TableReadOnly,
  },
  async created() {
    // if (!!!this.orderActivities.length)
    await this.getOrderActivities({
      order: this.docNo,
    });
  },

  computed: {
    ...mapState({
      orderActivities: (state) => state.moduleOrders.orderActivities,
    }),
    ...mapGetters({
      docNo: "moduleOrders/docNo",
    }),
    headerTable() {
      return generateHeaderData(this.header);
    },
  },
  data() {
    return {
      header: [
        { text: "Date", value: "date", width: "15%" },
        { text: "Time", value: "time", width: "15%" },
        { text: "Activity", value: "trans_desc" },
        { text: "User", value: "loginName", width: "20%" },
      ],
    };
  },
  methods: {
    ...mapActions({
      getOrderActivities: "moduleOrders/getOrderActivities",
    }),
    async loadData() {
      await this.getOrderActivities({
        order: this.docNo,
      });
    },
  },
};
</script>

<style lang="scss">
.table--word-wraper {
  table {
    table-layout: fixed;
    td {
      overflow-wrap: break-word;
    }
  }
}
</style>
