<template>
  <v-container class="dark-blue pa-1" fluid>
    <v-row>
      <v-col
        v-for="(detail, index) in detailsFields"
        :key="detail.title"
        class="pa-1"
        :style="{ order: detail.order ? 3 : index + 1, height: 'auto' }"
      >
        <v-card elevation="5" class="pa-0 dark-blue fill-height">
          <v-list-item-content class="ma-0 pa-2 d-flex fill-height">
            <v-list-item-title class="ma-0 pa-2 title">
              {{ detail.title }}
            </v-list-item-title>
            <DetailField v-bind="detail" :obj="values" />
          </v-list-item-content>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DetailField from "@/components/OM/Quotes/DetailField.vue";

export default {
  props: ["detailsFields", "values"],

  components: {
    DetailField,
  },
};
</script>

<style scoped>
.pa-l-1 {
  padding-left: 1rem;
}

.ma-t {
  margin-top: 0.5rem;
  /* margin-bottom: 0.25rem; */
  padding-left: 1rem;
  padding-right: 1rem;
}

.title {
  font-size: 1rem !important;
  line-height: 1rem;
  text-align: center !important;
}
</style>

<style scoped lang="scss">
.title {
  font-weight: bold;
  color: #3284f4;
  text-transform: capitalize;
}
.border-bottom {
  border-bottom: thin solid #3284f4;
}
.subtitle {
  white-space: initial;
  text-align: center;
  font-weight: 400;
}
</style>
