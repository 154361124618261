import { render, staticRenderFns } from "./DetailField.vue?vue&type=template&id=c35b623e&scoped=true"
import script from "./DetailField.vue?vue&type=script&lang=js"
export * from "./DetailField.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c35b623e",
  null
  
)

export default component.exports