<template>
  <v-data-table
    class="overflow-y-auto elevation-5 main-table table-readOnly"
    dense
    fixed-header
    hide-default-footer
    height="calc(100vh - 200px)"
    disable-pagination
    show-expand
    expand-icon="mdi-file-tree"
    single-expand
    :header-props="{ sortIcon: 'mdi-chevron-up' }"
    :headers="headerTable"
    :items="items"
    :loading="loadingFlag"
    @click:row="setDetails"
    @item-expanded="clickExpanded"
    :mobile-breakpoint="0"
  >
    <template v-slot:[`item.vendStepDescr`]="{ item }">
      <v-chip
        label
        :color="
          item.vendStepDescr == 'New'
            ? 'indigo'
            : item.vendStepDescr == 'Pending Pick Up'
            ? 'purple'
            : item.vendStepDescr == 'Paid'
            ? 'green'
            : item.vendStepDescr.startsWith('Closed')
            ? 'red lighten-1'
            : item.vendStepDescr == 'Completed'
            ? 'green darken-4'
            : ''
        "
      >
        <span class="ma-0 rounded-0">{{ item.vendStepDescr }} </span>
      </v-chip>
    </template>
    <template v-slot:[`item.origin_login_name`]="{ item }">
      {{ item.origin_login_name ? item.origin_login_name : "[ Nereus Sales Team ]" }}
    </template>
    <template v-slot:[`item.docDate`]="{ item }">
      {{ parseDate(item.docDate) }}
    </template>
    <template v-slot:[`item.vit_vendorTotal`]="{ item }">
      {{ `$ ${parseFloat(item.vit_vendorTotal).toFixed(2)}` }}
    </template>
    <template v-slot:[`item.Depots`]="{ item }">
      <v-row class="flex-row justify-center">
        <v-col class="pa-1 col-ajust" v-for="(depot, index) of item.depots" :key="index">
          <v-chip label small color="primary">
            <span class="ma-0 rounded-0 text-body-1">{{ depot }} </span>
          </v-chip>
        </v-col>
      </v-row>
    </template>
    <template v-slot:[`item.releases_ref`]="{ item }">
      <!-- <v-row class="flex-row justify-center">
          <v-col
            class="pa-1 col-ajust"
            v-for="(release, index) of item.releases"
            :key="index"
          >
            <v-chip label small color="primary">
              <span class="ma-0 rounded-0 text-body-1">{{ release }} </span>
            </v-chip>
          </v-col>
        </v-row> -->

      <v-row class="flex-row justify-center">
        <v-col cols="auto" v-for="(release, index) of item.releases" :key="index">
          <v-chip
            label
            small
            class="ma-1"
            color="primary"
            v-if="index < (item['releaseFlag'] ? item.releases.length : maxReleases)"
          >
            <span class="ma-0 rounded-0 text-body-1">{{ release }} </span>
          </v-chip>
        </v-col>
        <v-col cols="12" class="text-end" v-if="item.releases.length > maxReleases">
          <v-tooltip bottom color="primary">
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                icon
                color="#20212E"
                v-if="item.releases.length"
                @click="releaseData(item)"
              >
                <v-icon small color="green" v-if="!item['releaseFlag']"
                  >mdi-arrow-expand</v-icon
                >
                <v-icon small color="red" v-else>mdi-arrow-collapse</v-icon>
              </v-btn>
            </template>
            <span class="text-subtitle-1 font-weight-bold">
              {{ !item["releaseFlag"] ? "Expand" : "Collapse" }}</span
            >
          </v-tooltip>
        </v-col>
      </v-row>
    </template>
    <template v-slot:[`item.ContainersNo`]="{ item }">
      <v-row class="flex-row justify-center">
        <v-col cols="auto" v-for="(container, index) of item.containers" :key="index">
          <v-chip
            label
            small
            class="ma-1"
            color="primary"
            v-if="index < (item['containerFlag'] ? item.containers.length : maxContainer)"
          >
            <span class="ma-0 rounded-0 text-body-1">{{ container }} </span>
          </v-chip>
        </v-col>
        <v-col cols="12" class="text-end" v-if="item.containers.length > maxContainer">
          <v-tooltip bottom color="primary">
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                icon
                color="#20ddd2E"
                v-if="item.containers.length"
                @click="containerData(item)"
              >
                <v-icon small color="green" v-if="!item['containerFlag']"
                  >mdi-arrow-expand</v-icon
                >
                <v-icon small v-else color="red">mdi-arrow-collapse</v-icon>
              </v-btn>
            </template>
            <span class="text-subtitle-1 font-weight-bold">
              {{ !item["containerFlag"] ? "Expand" : "Collapse" }}</span
            >
          </v-tooltip>
        </v-col>
      </v-row>
    </template>
    <template v-slot:[`item.isTBD`]="{ value }">
      <v-chip
        label
        small
        :color="
          value === 'TBD'
            ? 'green darken-1'
            : value === 'DETERMINED'
            ? 'deep-purple lighten-1'
            : 'cyan lighten-1'
        "
      >
        <span class="ma-0 rounded-0"> {{ value }} </span>
      </v-chip>
    </template>
    <template #[`item.v_picked_up`]="{ item }">
      {{ item.v_picked_up || "-" }}
    </template>
  </v-data-table>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { parseDate } from "@/helpers/mocks/dates";
import { HEADER } from "@/helpers/mocks/orders";
import ItemsTable from "@/components/OM/Details/ItemsTable";
import { generateHeaderData } from "@/utils/tables.js";
export default {
  props: ["items", "loadingFlag"],
  computed: {
    ...mapState({
      eoo: (state) => state.moduleOrders.eoo,
    }),
    headerTable() {
      return generateHeaderData(HEADER);
    },
  },
  components: {
    ItemsTable,
  },
  data() {
    return {
      itemsTableFlag: false,
      columnSelected: "",
      containerModal: false,
      maxContainer: 5,
      maxReleases: 5,
      expandedHeader: [
        { text: "Line", value: "itemID" },
        { text: "Item Ref", value: "docItemID" },
        { text: "Depot", value: "Location", color: "green" },
        { text: "City", value: "cityName" },
        { text: "State", value: "stateName" },
        { text: "Country", value: "countryName" },
        { text: "Size", value: "sizeDescr", color: "green" },
        { text: "Type", value: "typeCode", color: "green" },
        { text: "Condition", value: "condDescr" },
        { text: "Release #", value: "rn2_release_ref" },
        { text: "Qty", value: "qty" },
        { text: "Unit Price", value: "buyerPrice" },
        { text: "Total", value: "Total" },
        { text: "", value: "actions" },
      ],
    };
  },
  methods: {
    parseDate,
    ...mapMutations({
      setDetailsStore: "moduleOrders/setDetails",
    }),
    setDetails(item) {
      if (this.columnSelected !== "container" && this.columnSelected !== "release") {
        this.setDetailsStore(item);
        this.$router.push(`/orders/${item.docNo}`);
      } else {
        this.columnSelected = "";
      }
    },
    ...mapActions({
      getOrderDetailByDocNo: "moduleOrders/moduleDetails/getOrderDetailByDocNo",
    }),

    async clickExpanded({ item, value }) {
      this.itemsTableFlag = true;
      await this.getOrderDetailByDocNo(item.docNo);
      this.itemsTableFlag = false;
    },
    containerData(item) {
      this.$set(item, "containerFlag", !item["containerFlag"]);

      this.columnSelected = "container";
    },
    releaseData(item) {
      this.$set(item, "releaseFlag", !item["releaseFlag"]);

      this.columnSelected = "release";
    },
  },
};
</script>

<style></style>
