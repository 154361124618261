<template>
  <div>
    <v-row justify="end">
      <v-col
        style="
          flex: none;
          width: fit-content;
          display: flex;
          flex-flow: row;
          gap: 10px;
        "
      >
        <v-btn
          class="button elevation-5"
          small
          color="blue"
          @click="showUploadDocModal"
          icon
        >
          <v-icon>mdi-file-upload</v-icon>
        </v-btn>

        <v-col>
          <v-btn
            class="button elevation-5"
            small
            color="green"
            @click="refresh"
            icon
          >
            <v-icon>mdi-cached</v-icon>
          </v-btn>
        </v-col>
      </v-col>
    </v-row>

    <DocumentCardTable
      :items="files"
      :headerTable="headerTable"
      @action="action"
      :title="title"
    />
    <DocumentsModal
      :show="showModal"
      :width="100"
      title="Upload document"
      @showModal="showUploadDocModal"
      :details="selectedDetails"
    />
  </div>
</template>

<script>
import TableReadOnly from "@/components/General/TableReadOnly.vue";
import DocumentsModal from "@/components/Organizations/UploadDocumentsModal.vue";
import DocumentCardTable from "@/components/Organizations/CardTable.vue";
import { generateHeaderData } from "@/utils/tables.js";
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  props: ["details"],
  components: {
    TableReadOnly,
    DocumentsModal,
    DocumentCardTable,
  },
  data() {
    return {
      header: [
        { text: "Title", value: "filename", width: "50%" },
        { text: "File Date", value: "createdDate" },
        { text: "User", value: "updateByName" },
        { text: "", value: "actions", width: "10%" },
      ],
      files: [],
      showModal: false,
      title: "documents",
      docNo: "",
      selectedDetails: null,
    };
  },
  computed: {
    ...mapState(["orderFiles"]),
    ...mapState({
      tabSelected: (state) => state.moduleOrders.tabSelected,
    }),
    headerTable() {
      return generateHeaderData(this.header);
    },
  },
  watch: {
    async orderFiles() {
      let filesNew = [];

      for (let item of this.orderFiles) {
        item.signedUrl = await this.getSignedURL({
          filename: item.filename,
        }).then((response) => response.url);

        filesNew.push(item);
      }

      this.files = [...filesNew];
    },
  },
  methods: {
    ...mapActions([
      "deleteFile",
      "getOrderFiles",
      "getSignedURL",
      "makePublicFile",
    ]),

    refresh() {
      this.loadData();
      this.selectedDetails = this.details || [this.tabSelected.details];
    },

    async loadData() {
      this.docNo = this.details
        ? this.details[0].docNo
        : this.tabSelected.details.docNo;

      await this.getOrderFiles({
        sourceID: this.docNo,
      });

      this.records = [...this.orderFiles];
    },
    action({ item, save, eventAction }) {
      let eventTrigger = `${eventAction}${this.title}`.toString();
      console.log(eventTrigger);
      this[eventTrigger]({ item, save });
    },
    deletedocuments({ item }) {
      this.deleteFile({
        filename: item.filename,
        organization_id: 0,
        sourceID: this.docNo,
      });
    },
    showUploadDocModal() {
      console.log("Changing the modal state");
      this.showModal = !this.showModal;
    },
  },
  created() {
    this.loadData();
    this.selectedDetails = this.details || [this.tabSelected.details];
  },
};
</script>

<style></style>
